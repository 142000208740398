import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Home } from "./pages/home";
import { useEffect, useState } from "react";

function App() {
  const [loading, setLoading] = useState(true);

  // This will run one time after the component mounts
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
