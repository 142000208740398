import { Navbar } from "../components/navbar";
import { useEffect } from "react";
import { SectionHero } from "../components/home/sectionHero";
import { SectionGreen } from "../components/home/sectionGreen";
import { SectionAvantages } from "../components/home/sectionAvantages";
import { IaSection } from "../components/home/IaSection";
import { ChallengeSection } from "../components/home/challengeSection";
import { MarcheSection } from "../components/home/marcheSection";
import { StartSection } from "../components/home/startSection";
import { SectionNutrition } from "../components/home/sectionNutrition";
import { Footer } from "../components/footer";

export const Home = () => {

  useEffect(() => {
    const sectionStart = document.querySelector(".section-nutrition") as HTMLElement | null;
    const sectionEnd = document.querySelector(".section-objectif")?.nextElementSibling as HTMLElement | null;
    const sectionOne = document.querySelector(".section-oneee") as HTMLElement | null;
    const title = sectionOne?.querySelector(".title-base") as HTMLElement | null;
    const icons = document.querySelectorAll(".iconn-ai") as NodeListOf<HTMLElement>;
    const heroSection = document.getElementById("hero-section") as HTMLElement | null;
    const navbar = document.querySelector(".container-nav") as HTMLElement | null;
    const navScroll = document.querySelector(".navscroll") as HTMLElement | null;
    const nutritionParagraph = document.getElementById("nutrition-paragraph") as HTMLElement | null;
    const body = document.body;
    let lastScrollTop = 0;

    const handleScroll = () => {
      const offset = 300;

      // Transition background to white when the nutrition paragraph is visible
      if (nutritionParagraph) {
        const nutritionParagraphRect = nutritionParagraph.getBoundingClientRect();
        const isVisible = nutritionParagraphRect.top < window.innerHeight;
        if (isVisible) {
          document.body.style.backgroundColor = "white";
        } else {
          document.body.style.backgroundColor = "#0FCC86"; // Reset to green if not visible
        }
      }

      // Dark Mode Toggle based on device width
      if (window.innerWidth <= 768) { // Mobile devices
        if (sectionOne) {
          const sectionOneRect = sectionOne.getBoundingClientRect();
          const isSectionVisible = sectionOneRect.bottom > 0 && sectionOneRect.top < window.innerHeight;

          if (isSectionVisible) {
            body.classList.add("dark-mode");
            title.style.color = "white";
            icons.forEach((icon) => {
              icon.style.opacity = "1";
            });
          } else {
            body.classList.remove("dark-mode");
            title.style.color = "black";
            icons.forEach((icon) => {
              icon.style.opacity = "0";
            });
          }
        }
      } else { // Desktop devices
        if (sectionOne && title) {
          const titleRect = title.getBoundingClientRect();
          const isVisible = titleRect.top < window.innerHeight && titleRect.bottom >= 0;

          if (isVisible) {
            body.classList.add("dark-mode");
            title.style.color = "white";
            icons.forEach((icon) => {
              icon.style.opacity = "1";
            });
          } else {
            body.classList.remove("dark-mode");
            title.style.color = "black";
            icons.forEach((icon) => {
              icon.style.opacity = "0";
            });
          }
        }
      }

      // Hero Section Opacity Change
      if (heroSection) {
        const scrollRange = window.innerHeight * 0.8;
        const newOpacity = 1 - Math.min(window.pageYOffset / scrollRange, 1);
        heroSection.style.opacity = newOpacity.toString();
      }

      // Navbar and Navscroll Hide on Scroll
      const st = window.pageYOffset || document.documentElement.scrollTop;
      if (navbar) {
        navbar.style.top = st > lastScrollTop ? "-120px" : "20px";
      }
      if (navScroll) {
        navScroll.style.top = st > lastScrollTop ? "-120px" : "20px";
      }
      lastScrollTop = st <= 0 ? 0 : st;
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

  }, []);

  return (
    <div>
      <Navbar />
      <SectionHero />
      <SectionGreen />
      <SectionNutrition />
      <SectionAvantages />
      <IaSection />
      <MarcheSection />
      <StartSection />
      <Footer />
    </div>
  );
};
