import ImgObjcetif1 from "../../images/slides/objectif/img-objectif-l1.png";
import ImgObjcetif2 from "../../images/slides/objectif/img-objectif-l2.png";
import ImgObjcetif3 from "../../images/slides/objectif/img-objectif-l3.png";
import ImgObjcetif4 from "../../images/slides/objectif/img-objectif-l4.png";
import ImgObjcetif5 from "../../images/slides/objectif/img-objectif-l5.png";
import ImgObjcetif6 from "../../images/slides/objectif/img-objectif-l6.png";
import checkMark from "../../images/icones/checkmark.png";

export const SectionNutrition = () => {
    return (
        <section
            id="section-nutrition" // Ajout de l'ID ici
            className="section-one section-objectif black-background"
        >
            <div className="container-one">
                <div className="row d-flex align-items-center">
                    <div
                        className="col-12 col-sm-12 col-md-6 col-lg-5"
                        data-aos="fade-up"
                        data-aos-duration={700}
                        data-aos-delay={300}
                        data-aos-easing="ease"
                    >
                        <span className="pill-section d-inline-block primary-green mb-3">
                            Nutrition
                        </span>
                        <h2 className="title-2 mb-4">
                            Qui s'adapte <br />{" "}
                            <span className="primary-green">à tous type d'objectifs</span>
                        </h2>
                        <p
                            id="nutrition-paragraph" // Ajout de l'ID ici
                            className="text-2 mb-4"
                            data-aos="fade-up"
                            data-aos-duration={600}
                            data-aos-delay={290}
                            data-aos-easing="ease"
                        >
                            Avec Fleesh, transformez votre approche de la nutrition grâce à un
                            coach alimentaire personnel propulsé par l'intelligence artificielle.
                            Conçu pour s'adapter précisément à vos objectifs de santé, Fleesh
                            offre une expérience sur mesure qui évolue avec vous.
                        </p>
                        <div className="advantage mb-2 d-flex align-items-center justify-content-start">
                            <img
                                className="round-icon me-2"
                                src={checkMark}
                                alt=""
                            />
                            <p className="text-2-bold m-0">Adaptation continue à vos progrès </p>
                        </div>
                        <div className="advantage mb-5 d-flex align-items-center justify-content-start">
                            <img
                                className="round-icon me-2"
                                src={checkMark}
                                alt=""
                            />
                            <p className="text-2-bold m-0">Conseils personnalisés et précis </p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 offset-lg-1">
                        <div
                            className="image-stack "
                            style={{ position: "relative", width: "100%" }}
                        >
                            {/* Image de fond qui définit la taille du conteneur */}
                            <img
                                src={ImgObjcetif1}
                                className="img-fluid"
                                style={{ width: "100%", display: "block" }}
                                data-aos="fade-left"
                                data-aos-duration={400}
                                data-aos-delay={550}
                                data-aos-easing="ease"
                            />
                            {/* Images superposées */}
                            <img
                                src={ImgObjcetif2}
                                className="img-overlay"
                                alt=""
                                data-aos="fade-right"
                                data-aos-duration={400}
                                data-aos-delay={550}
                                data-aos-easing="ease"
                            />
                            <img
                                src={ImgObjcetif3}
                                className="img-overlay"
                                alt=""
                                data-aos="fade-right"
                                data-aos-duration={400}
                                data-aos-delay={500}
                                data-aos-easing="ease"
                            />
                            <img
                                src={ImgObjcetif4}
                                className="img-overlay"
                                alt=""
                                data-aos="fade-up"
                                data-aos-duration={400}
                                data-aos-delay={450}
                                data-aos-easing="ease"
                            />
                            <img
                                src={ImgObjcetif5}
                                className="img-overlay"
                                alt=""
                                data-aos="fade-right"
                                data-aos-duration={400}
                                data-aos-delay={600}
                                data-aos-easing="ease"
                            />
                            <img
                                src={ImgObjcetif6}
                                className="img-overlay"
                                alt=""
                                data-aos="fade-right"
                                data-aos-duration={400}
                                data-aos-delay={600}
                                data-aos-easing="ease"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
