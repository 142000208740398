import imgLn1Desktop from "../../images/bento1/image1/desktop/img-1-l1.png";
import imgLn2Desktop from "../../images/bento1/image1/desktop/img-1-l2.png";
import imgLn3Desktop from "../../images/bento1/image1/desktop/img-1-l3.png";
import imgLn4Desktop from "../../images/bento1/image1/desktop/img-1-l4.png";
import imgLn5Desktop from "../../images/bento1/image1/desktop/img-1-l5.png";

import imgLn1Mobile from "../../images/bento1/image1/mobile/img-1-mobile-l1.png";
import imgLn2Mobile from "../../images/bento1/image1/mobile/img-1-mobile-l2.png";
import imgLn3Mobile from "../../images/bento1/image1/mobile/img-1-mobile-l3.png";
import imgLn4Mobile from "../../images/bento1/image1/mobile/img-1-mobile-l4.png";
import imgLn5Mobile from "../../images/bento1/image1/mobile/img-1-mobile-l5.png";

import imgLn2Desktop1 from "../../images/bento1/image2/img-2-l1.png";
import imgLn2Desktop2 from "../../images/bento1/image2/img-2-l2.png";
import imgLn2Desktop3 from "../../images/bento1/image2/img-2-l3.png";

import imgLn3Desktop1 from "../../images/bento1/image3/img-3-l1.png";
import imgLn3Desktop2 from "../../images/bento1/image3/img-3-l2.png";
import imgLn3Desktop3 from "../../images/bento1/image3/img-3-l3.png";
import imgLn3Desktop4 from "../../images/bento1/image3/img-3-l4.png";
import imgLn3Desktop5 from "../../images/bento1/image3/img-3-l5.png";
import imgLn3Desktop6 from "../../images/bento1/image3/img-3-l6.png";
import imgLn3Desktop7 from "../../images/bento1/image3/img-3-l7.png";

import imgLn4Desktop1 from "../../images/bento1/image4/desktop/img-4-l1.png";
import imgLn4Desktop2 from "../../images/bento1/image4/desktop/img-4-l2.png";
import imgLn4Desktop3 from "../../images/bento1/image4/desktop/img-4-l3.png";
import imgLn4Desktop4 from "../../images/bento1/image4/desktop/img-4-l4.png";
import imgLn4Desktop5 from "../../images/bento1/image4/desktop/img-4-l5.png";
import imgLn4Desktop6 from "../../images/bento1/image4/desktop/img-4-l6.png";

import imgLn4Mobile1 from "../../images/bento1/image4/mobile/img-4-mobile-l1.png";
import imgLn4Mobile2 from "../../images/bento1/image4/mobile/img-4-mobile-l2.png";
import imgLn4Mobile3 from "../../images/bento1/image4/mobile/img-4-mobile-l3.png";
import imgLn4Mobile4 from "../../images/bento1/image4/mobile/img-4-mobile-l4.png";
import imgLn4Mobile5 from "../../images/bento1/image4/mobile/img-4-mobile-l5.png";

import imgLn5Desktop1 from "../../images/bento1/image5/desktop/img-5-l1.png";
import imgLn5Desktop2 from "../../images/bento1/image5/desktop/img-5-l2.png";
import imgLn5Desktop3 from "../../images/bento1/image5/desktop/img-5-l3.png";
import imgLn5Desktop4 from "../../images/bento1/image5/desktop/img-5-l4.png";
import imgLn5Desktop5 from "../../images/bento1/image5/desktop/img-5-l5.png";
import imgLn5Desktop6 from "../../images/bento1/image5/desktop/img-5-l6.png";

import imgLn5Mobile1 from "../../images/bento1/image5/mobile/img-5-mobile-l1.png";
import imgLn5Mobile2 from "../../images/bento1/image5/mobile/img-5-mobile-l2.png";
import imgLn5Mobile3 from "../../images/bento1/image5/mobile/img-5-mobile-l3.png";
import imgLn5Mobile4 from "../../images/bento1/image5/mobile/img-5-mobile-l4.png";

import imgLn6Desktop1 from "../../images/bento1/image6/img-6-l1.png";
import imgLn6Desktop2 from "../../images/bento1/image6/img-6-l2.png";
import imgLn6Desktop3 from "../../images/bento1/image6/img-6-l3.png";
import imgLn6Desktop4 from "../../images/bento1/image6/img-6-l4.png";
import imgLn6Desktop5 from "../../images/bento1/image6/img-6-l5.png";

export const SectionAvantages = () => {
    return (
      <div id="avantages" className="section-two  black-background">
      <div className="container-one">
        <div className="row mb-5">
          <div className="col-12 col-md-6 offset-md-3 text-center">
            <span className="pill-section d-inline-block primary-green mb-3">
              Avantages
            </span>
            <h2
              className="title-2 mb-4"
              data-aos="fade-up"
              data-aos-duration={400}
              data-aos-delay={100}
              data-aos-easing="ease"
            >
              Avec tout pour faciliter l'atteinte votre objectif, {" "}
              <span className="primary-green">une bonne fois pour toute</span>
            </h2>
            <p
              className="text-2 mb-5"
              data-aos="fade-up"
              data-aos-duration={400}
              data-aos-delay={150}
              data-aos-easing="ease"
            >
              Avec Fleesh, chaque repas devient une étape stratégique vers vos
              objectifs de santé, combinant simplicité et plaisir. Embarquez
              dans un parcours personnalisé où chaque bouchée est optimisée
              pour vous rapprocher de votre bien-être optimal, sans compromis
              sur le goût ou la satisfaction
            </p>
          </div>
        </div>
        <div className="row gx-3 h-100">
          {/*BIG CARD*/}
          <div
            className="col-12 col-sm-12 col-md-12 col-lg-8 d-flex"
            data-aos="fade-right"
            data-aos-duration={400}
            data-aos-delay={100}
            data-aos-easing="ease"
          >
            <div className="card-1 w-100 d-flex flex-column flex-md-row mb-3">
              <div className="col-12 col-md-5 d-flex align-items-center justify-content-center p-4">
                <div>
                  <h2 className="title-3">
                    Des repas adaptés{" "}
                    <span className="primary-green">
                      à vos besoins et préférences spécifiques
                    </span>
                  </h2>
                  <p className="text-3 mb-4">
                    Explorez un large choix de recettes, toutes conçues pour
                    être aussi délicieuses que nutritives mais surtout
                    adaptées à votre objectif
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-7 px-0">
                {/* Image pour tablettes, écrans d'ordinateur et grands écrans */}
                <div
                  className="image-stack d-none d-md-block"
                  style={{ position: "relative", width: "100%" }}
                >
                  {/* Image de fond qui définit la taille du conteneur */}
                  <img
                    src={imgLn1Desktop}
                    className="img-fluid"
                    style={{ display: "block" }}
                    data-aos="fade-left"
                    data-aos-duration={400}
                    data-aos-delay={450}
                    data-aos-easing="ease"
                  />
                  {/* Images superposées */}
                  <img
                    src={imgLn2Desktop}
                    style={{ height: "130%" }}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-left"
                    data-aos-duration={600}
                    data-aos-delay={500}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn3Desktop}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-up"
                    data-aos-duration={400}
                    data-aos-delay={800}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn4Desktop}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-up"
                    data-aos-duration={400}
                    data-aos-delay={900}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn5Desktop}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-up"
                    data-aos-duration={400}
                    data-aos-delay={1000}
                    data-aos-easing="ease"
                  />
                </div>
                {/* Image pour mobile */}
                <div
                  className="image-stack d-block d-md-none"
                  style={{ position: "relative", width: "100%" }}
                >
                  {/* Image de fond qui définit la taille du conteneur */}
                  <img
                    src={imgLn1Mobile}
                    className="img-fluid"
                    style={{ width: "100%", display: "block" }}
                    data-aos="fade-up"
                    data-aos-duration={400}
                    data-aos-delay={450}
                    data-aos-easing="ease"
                  />
                  {/* Images superposées */}
                  <img
                    src={imgLn2Mobile}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-up"
                    data-aos-duration={600}
                    data-aos-delay={500}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn3Mobile}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-down"
                    data-aos-duration={300}
                    data-aos-delay={800}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn4Mobile}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-down"
                    data-aos-duration={300}
                    data-aos-delay={900}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn5Mobile}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-down"
                    data-aos-duration={300}
                    data-aos-delay={1000}
                    data-aos-easing="ease"
                  />
                </div>
              </div>
            </div>
          </div>
          {/*SMALL CARD*/}
          <div
            className="col-12 col-sm-12 col-md-12 col-lg-4 d-flex"
            data-aos="fade-down"
            data-aos-duration={400}
            data-aos-delay={200}
            data-aos-easing="ease"
          >
            <div className="card-1 w-100 d-flex flex-column mb-3">
              <div className="col-12 px-0">
                {/* Image pour mobile */}
                <div
                  className="image-stack "
                  style={{ position: "relative", width: "100%" }}
                >
                  {/* Image de fond qui définit la taille du conteneur */}
                  <img
                    src={imgLn2Desktop1}
                    className="img-fluid"
                    style={{ width: "100%", display: "block" }}
                    data-aos="fade-down"
                    data-aos-duration={400}
                    data-aos-delay={450}
                    data-aos-easing="ease"
                  />
                  {/* Images superposées */}
                  <img
                    src={imgLn2Desktop2}
                    className="img-overlay"
                    style={{ width: "100%", display: "block" }}
                    data-aos="fade-down"
                    data-aos-duration={600}
                    data-aos-delay={500}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn2Desktop3}
                    className="img-overlay"
                    style={{ width: "100%", display: "block" }}
                    data-aos="fade-down"
                    data-aos-duration={300}
                    data-aos-delay={700}
                    data-aos-easing="ease"
                  />
                </div>
              </div>
              <div className="col-12 d-flex align-items-center justify-content-center p-4">
                <div>
                  <h2 className="title-3">
                    La connaissance alimentaire{" "}
                    <span className="primary-green">à portée de main</span>
                  </h2>
                  <p className="text-3 mb-4">
                    Adaptez facilement vos repas et habitudes alimentaires
                    avec notre système modulable.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/*SMALL CARD*/}
          <div
            className="col-12 col-sm-12 col-md-12 col-lg-4 d-flex"
            data-aos="fade-up"
            data-aos-duration={400}
            data-aos-delay={300}
            data-aos-easing="ease"
          >
            <div className="card-1 w-100 d-flex flex-column mb-3">
              <div className="col-12 px-0">
                {/* Image pour mobile */}
                <div
                  className="image-stack "
                  style={{ position: "relative", width: "100%" }}
                >
                  {/* Image de fond qui définit la taille du conteneur */}
                  <img
                    src={imgLn3Desktop1}
                    className="img-fluid"
                    style={{ width: "100%", display: "block" }}
                    data-aos="fade-down"
                    data-aos-duration={400}
                    data-aos-delay={400}
                    data-aos-easing="ease"
                  />
                  {/* Images superposées */}
                  <img
                    src={imgLn3Desktop2}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-left"
                    data-aos-duration={400}
                    data-aos-delay={600}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn3Desktop3}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-right"
                    data-aos-duration={200}
                    data-aos-delay={800}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn3Desktop4}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-right"
                    data-aos-duration={200}
                    data-aos-delay={900}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn3Desktop5}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-right"
                    data-aos-duration={200}
                    data-aos-delay={1000}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn3Desktop6}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-right"
                    data-aos-duration={200}
                    data-aos-delay={1100}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn3Desktop7}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-right"
                    data-aos-duration={200}
                    data-aos-delay={1200}
                    data-aos-easing="ease"
                  />
                </div>
              </div>
              <div className="col-12 d-flex align-items-center justify-content-center p-4">
                <div>
                  <h2 className="title-3">
                    Vos courses directement{" "}
                    <span className="primary-green">
                      {" "}
                      à la maison en 3 heures
                    </span>
                  </h2>
                  <p className="text-3 mb-4">
                    Gagnez du temps, Fleesh vous livre vos courses sur mesure
                    directement à la maison depuis vos grande enseignes
                    préférées
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/*BIG CARD*/}
          <div
            className="col-12 col-sm-12 col-md-12 col-lg-8 d-flex"
            data-aos="fade-left"
            data-aos-duration={400}
            data-aos-delay={100}
            data-aos-easing="ease"
          >
            <div className="card-1 w-100 d-flex flex-column flex-md-row mb-3">
              <div className="col-12 col-md-5 d-flex align-items-center justify-content-center p-4">
                <div>
                  <h2 className="title-3">
                    Une optimisation de votre budget{" "}
                    <span className="primary-green">
                      qui réduit le gaspillage
                    </span>
                  </h2>
                  <p className="text-3 mb-4">
                    Optimisez votre budget avec des plans qui réduisent
                    également le gaspillage alimentaire, grâce à l’IA
                    adaptative.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-7 px-0">
                {/* Image pour tablettes, écrans d'ordinateur et grands écrans */}
                <div
                  className="image-stack d-none d-md-block"
                  style={{ position: "relative", width: "100%" }}
                >
                  {/* Image de fond qui définit la taille du conteneur */}
                  <img
                    src={imgLn4Desktop1}
                    className="img-fluid"
                    style={{
                      width: "100%",
                      display: "block",
                      height: "230%",
                    }}
                    data-aos="fade-left"
                    data-aos-duration={400}
                    data-aos-delay={450}
                    data-aos-easing="ease"
                  />
                  {/* Images superposées */}
                  <img
                    src={imgLn4Desktop2}
                    className="img-overlay"
                    alt=""
                    style={{ height: "130%" }}
                    data-aos="fade-left"
                    data-aos-duration={400}
                    data-aos-delay={500}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn4Desktop3}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-left"
                    data-aos-duration={400}
                    data-aos-delay={600}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn4Desktop4}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-right"
                    data-aos-duration={400}
                    data-aos-delay={700}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn4Desktop5}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-right"
                    data-aos-duration={400}
                    data-aos-delay={750}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn4Desktop6}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-right"
                    data-aos-duration={400}
                    data-aos-delay={800}
                    data-aos-easing="ease"
                  />
                </div>
                {/* Image pour mobile */}
                <div
                  className="image-stack d-block d-md-none"
                  style={{ position: "relative", width: "100%" }}
                >
                  {/* Image de fond qui définit la taille du conteneur */}
                  <img
                    src={imgLn4Mobile1}
                    className="img-fluid"
                    style={{ width: "100%", display: "block" }}
                    data-aos="fade-up"
                    data-aos-duration={400}
                    data-aos-delay={400}
                    data-aos-easing="ease"
                  />
                  {/* Images superposées */}
                  <img
                    src={imgLn4Mobile2}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-up"
                    data-aos-duration={400}
                    data-aos-delay={600}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn4Mobile3}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-up"
                    data-aos-duration={400}
                    data-aos-delay={700}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn4Mobile4}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-down"
                    data-aos-duration={400}
                    data-aos-delay={800}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn4Mobile5}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-down"
                    data-aos-duration={400}
                    data-aos-delay={900}
                    data-aos-easing="ease"
                  />
                </div>
              </div>
            </div>
          </div>
          {/*BIG CARD*/}
          <div
            className="col-12 col-sm-12 col-md-12 col-lg-8 d-flex"
            data-aos="fade-right"
            data-aos-duration={400}
            data-aos-delay={100}
            data-aos-easing="ease"
          >
            <div className="card-1 w-100 d-flex flex-column flex-md-row mb-3">
              <div className="col-12 col-md-5 d-flex align-items-center justify-content-center p-4">
                <div>
                  <h2 className="title-3">
                    Votre chatbot nutritionnel IA{" "}
                    <span className="primary-green">à portée de mains</span>{" "}
                  </h2>
                  <p className="text-3 mb-4">
                    Explorez un large choix de recettes, toutes conçues pour
                    être aussi délicieuses que nutritives mais surtout
                    adaptées à votre objectif
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-7 px-0">
                {/* Image pour tablettes, écrans d'ordinateur et grands écrans */}
                <div
                  className="image-stack d-none d-md-block"
                  style={{ position: "relative", width: "100%" }}
                >
                  {/* Image de fond qui définit la taille du conteneur */}
                  <img
                    src={imgLn5Desktop1}
                    className="img-fluid"
                    style={{ width: "100%", display: "block" }}
                    data-aos="fade-left"
                    data-aos-duration={400}
                    data-aos-delay={200}
                    data-aos-easing="ease"
                  />
                  {/* Images superposées */}
                  <img
                    src={imgLn5Desktop2}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-left"
                    data-aos-duration={400}
                    data-aos-delay={400}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn5Desktop3}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-up"
                    data-aos-duration={400}
                    data-aos-delay={500}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn5Desktop4}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-down"
                    data-aos-duration={400}
                    data-aos-delay={600}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn5Desktop5}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-up"
                    data-aos-duration={400}
                    data-aos-delay={700}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn5Desktop6}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-down"
                    data-aos-duration={400}
                    data-aos-delay={800}
                    data-aos-easing="ease"
                  />
                </div>
                {/* Image pour mobile */}
                <div
                  className="image-stack d-block d-md-none"
                  style={{ position: "relative", width: "100%" }}
                >
                  {/* Image de fond qui définit la taille du conteneur */}
                  <img
                    src={imgLn5Mobile1}
                    className="img-fluid"
                    style={{ width: "100%", display: "block" }}
                    data-aos="fade-up"
                    data-aos-duration={400}
                    data-aos-delay={200}
                    data-aos-easing="ease"
                  />
                  {/* Images superposées */}
                  <img
                    src={imgLn5Mobile2}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-down"
                    data-aos-duration={400}
                    data-aos-delay={300}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn5Mobile3}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-down"
                    data-aos-duration={400}
                    data-aos-delay={500}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn5Mobile4}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-down"
                    data-aos-duration={400}
                    data-aos-delay={700}
                    data-aos-easing="ease"
                  />
                </div>
              </div>
            </div>
          </div>
          {/*SMALL CARD*/}
          <div
            className="col-12 col-sm-12 col-md-12 col-lg-4 d-flex"
            data-aos="fade-up"
            data-aos-duration={400}
            data-aos-delay={100}
            data-aos-easing="ease"
          >
            <div className="card-1 w-100 d-flex flex-column mb-3">
              <div className="col-12 px-0">
                {/* Image pour mobile */}
                <div
                  className="image-stack "
                  style={{ position: "relative", width: "100%" }}
                >
                  {/* Image de fond qui définit la taille du conteneur */}
                  <img
                    src={imgLn6Desktop1}
                    className="img-fluid"
                    style={{ width: "100%", display: "block" }}
                    data-aos="fade-down"
                    data-aos-duration={400}
                    data-aos-delay={200}
                    data-aos-easing="ease"
                  />
                  {/* Images superposées */}
                  <img
                    src={imgLn6Desktop2}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-down"
                    data-aos-duration={400}
                    data-aos-delay={300}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn6Desktop3}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-down"
                    data-aos-duration={400}
                    data-aos-delay={400}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn6Desktop4}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-down"
                    data-aos-duration={400}
                    data-aos-delay={500}
                    data-aos-easing="ease"
                  />
                  <img
                    src={imgLn6Desktop5}
                    className="img-overlay"
                    alt=""
                    data-aos="fade-down"
                    data-aos-duration={400}
                    data-aos-delay={600}
                    data-aos-easing="ease"
                  />
                </div>
              </div>
              <div className="col-12 d-flex align-items-center justify-content-center p-4">
                <div>
                  <h2 className="title-3">
                    <span className="primary-green">Un gain de temps</span>{" "}
                    quotidien
                  </h2>
                  <p className="text-3 mb-4">
                    Planifiez et préparez vos repas plus rapidement, tout en
                    contrôlant la variété.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}