

export const SectionHero = () => {  

    return (
        <section
        className="section-hero d-flex justify-content-center align-items-center"
        id="hero-section"
      >
     <video
      autoPlay
      muted
      loop
      playsInline
      style={{
        position: "absolute",
        width: "100%",
        left: 0,
        top: 0,
        height: "100%",
        objectFit: "cover",
        zIndex: -2,
      }}
    >
      <source src="/assets/video/video.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            zIndex: -1,
          }}
        ></div>
        <div className="container-one text-center">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-10 col-md-7">
              <h1
                className="title-1 mb-3 text-white mt-5"
                data-aos="fade-in"
                data-aos-duration={400}
                data-aos-delay={100}
                data-aos-easing="ease-in-out"
              >
                Révolutionner <br /> votre alimentation avec{" "}
                <span className="primary-green">
                  l’Intelligence Artificielle
                </span>
              </h1>
              <p className="text-2 white-70 mb-5">
                Découvrez une nouvelle ère de la nutrition personnalisée grâce à
                l'Intelligence Artificielle. Notre technologie avancée analyse
                vos habitudes alimentaires, vos préférences et vos objectifs
                pour vous proposer des plans de repas sur mesure.
              </p>
              <button
                className="primary-btn d-inline-block"
                disabled
               // data-bs-toggle="modal"
                //data-bs-target="#exampleModal"
              >
                Télécharger l'app
              </button>
              {/* Modal */}
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Titre de la modal
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <div className="modal-body">Contenu de la modal.</div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Fermer
                      </button>
                      <button type="button" className="btn btn-primary">
                        Enregistrer les modifications
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}