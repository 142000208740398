import lastCallImg from "../../images/lastcall.png";

export const StartSection = () => {

    return (
        <section className="section-one section-start  black-background">
  <div className="container-one">
    <div
      className="row "
      data-aos="fade-up"
      data-aos-duration={400}
      data-aos-delay={200}
      data-aos-easing="ease"
    >
      <div className="col-12 col-sm-10 col-md-10 col-lg-10 offset-lg-1">
           <div className="card-cta text-center">
      <div
      style={{
        backgroundImage: `url(${lastCallImg})`,
        backgroundSize: 'cover',
        padding: '140px 0 100px 0',
        borderRadius: 30,
      }}
    >
          <h2 className="title-2 white mb-4">
            Commencez à accomplir <br />{" "}
            <span className="primary-green">
              votre objectif dès aujourd'hui{" "}
            </span>
          </h2>
          <p className="text-2 white-70 mb-5">
            Lancez-vous avec un plan de repas conçu spécialement pour vous, prêt
            à transformer <br /> votre routine alimentaire et propulser votre
            bien-être.
          </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    )
}