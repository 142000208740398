import LogoWhite from "../images/logo-white.svg";


export const Navbar = () => {

    return (
      <>
      {/*NAV DESKTOP*/}
      <div className="container-nav fixed-top d-none d-lg-block" style={{ top: 20 }}>
      <div className="navbarz" style={{ transition: 'top 0.3s' }}>
        <div className="row align-items-center">
          <div className="col-3">
            <img src={LogoWhite} alt="Logo" />
          </div>
          <div className="col-6 text-center">
            <a className="simple-link m-3" href="#nutrition">
              Nutrition
            </a>
            <a className="simple-link m-3" href="#avantages">
              Avantages
            </a>
            <a className="simple-link m-3" href="#IA">
              Intelligence
            </a>
            <a className="simple-link m-3" href="#challanges">
              Challenges
            </a>
          </div>
          <div className="col-3 text-end">
            <button
              style={{border : 'none'}}
              className="primary-btn-small ms-2" 
                          //  data-bs-toggle="modal"
              //data-bs-target="#exampleModal"
            >
              Apple store
            </button>
            <button
              style={{border : 'none'}}
              className="primary-btn-small ms-2" 
            //  data-bs-toggle="modal"
              //data-bs-target="#exampleModal"
            >
              Google store
            </button>
          </div>
        </div>
      </div>
    </div>
      {/*NAV MOBILE*/}
      <div
        className="container-nav navscroll fixed-top d-block d-lg-none"
        style={{ top: 10 }}
      >
        <div
          className="navbarz-mobile"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            backdropFilter: "blur(20px)",
            padding: 15,
            borderRadius: 10
          }}
        >
          <div className="row">
            <div className="col-3">
              <img src={LogoWhite} alt="" width="70px" />
            </div>
            <div className="col-9 text-end">
              <button
                className="primary-btn-xsmall ms-2" 
                style={{border : 'none'}}
                //data-bs-toggle="modal"
                //data-bs-target="#exampleModal"
              >
                App Store
              </button>
              <button
                className="primary-btn-xsmall ms-2" 
                style={{border : 'none'}}
                //data-bs-toggle="modal"
                //data-bs-target="#exampleModal"
              >
                Google store
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
    
    )
}