import Img1 from "../../images/slides/howto/Frame 48575.png"
import Step2 from "../../images/slides/howto/step2.png"
import Step3 from "../../images/slides/howto/step3.png"


export const MarcheSection = () => {
  return (
    <section id="how" className="section-one section-how-to  black-background">
      <div className="container-one">
        <div className="row d-flex align-items-center">
          <div className="col-12 col-sm-12 col-md-6 col-lg-7">
            <span className="pill-section d-inline-block primary-green mb-3">
              Comment ça marche ?
            </span>
            <h2
              className="title-2 mb-4"
              data-aos="fade-up"
              data-aos-duration={600}
              data-aos-delay={150}
              data-aos-easing="ease"
            >
              <span className="primary-green">
                Commencez votre <br /> métamorphose
              </span>{" "}
              en 5 minutes
            </h2>
            <p
              className="text-2 mb-5"
              data-aos="fade-up"
              data-aos-duration={600}
              data-aos-delay={290}
              data-aos-easing="ease"
            >
              Trois étapes simples pour une alimentation personnalisée et un
              bien-être réinventé.
            </p>
          </div>
        </div>
        <div className="row">
          <div
            className="col-12 col-sm-4 col-lg-4"
            data-aos="fade-up"
            data-aos-duration={400}
            data-aos-delay={400}
            data-aos-easing="ease"
          >
            <div className="card-howto mb-5 border-1">
              <div>
                <img
                  className="img-fluid"
                  src={Img1}
                  alt=""
                />
              </div>
              <div className="padding-30">
                <h3 className="title-3">Répondez au questionnaire</h3>
                <p className="text-3">
                  L’IA utilisera vos réponses pour adapter votre programme sur
                  mesure
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-sm-4 col-lg-4"
            data-aos="fade-up"
            data-aos-duration={400}
            data-aos-delay={500}
            data-aos-easing="ease"
          >
            <div className="card-howto mb-5 border-1">
              <div>
                <img
                  className="img-fluid"
                  src={Step2}
                  alt=""
                />
              </div>
              <div className="padding-30">
                <h3 className="title-3">L’IA prépare votre programme</h3>
                <p className="text-3">
                  L’IA vous présente un plan hebdomadaire personnalisé basé sur vos
                  réponses.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-sm-4 col-lg-4"
            data-aos="fade-up"
            data-aos-duration={400}
            data-aos-delay={600}
            data-aos-easing="ease"
          >
            <div className="card-howto mb-5 border-1">
              <div>
                <img
                  className="img-fluid"
                  src={Step3}
                  alt=""
                />
              </div>
              <div className="padding-30">
                <h3 className="title-3">Commencez votre programme</h3>
                <p className="text-3">
                  Vous pouvez dès maintenant commencez votre grand voyage vers votre
                  objectif et une vie plus saine
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}