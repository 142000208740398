import nutrition1 from "../../images/slides/nutrition/img-nutrition-l1.png";
import nutrition2 from "../../images/slides/nutrition/img-nutrition-l2.png";
import nutrition3 from "../../images/slides/nutrition/img-nutrition-l3.png";
import nutrition4 from "../../images/slides/nutrition/img-nutrition-l4.png";
import nutrition5 from "../../images/slides/nutrition/img-nutrition-l5.png";

import checkMarkWhite from "../../images/icones/checkmark-white.png";

export const SectionGreen = () => {

    return (

          <section
          id="nutrition"
          className="section-one section-nutrition black-background"
        >
          <div className="container-one">
            <div className="row d-flex align-items-center">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div
                  className="image-stack "
                  style={{ position: "relative", width: "100%" }}
                >
                  {/* Image de fond qui définit la taille du conteneur */}
                  <img
                    src={nutrition1}
                    className="img-fluid"
                    style={{ width: "80%", display: "block" }}
                    data-aos="fade-down"
                    data-aos-duration={400}
                    data-aos-delay={400}
                    data-aos-easing="ease"
                  />
                  {/* Images superposées */}
                  <img
                    src={nutrition2}
                    className="img-fluid img-overlay"
                    style={{ width: "80%", display: "block" }}
                    data-aos="fade-up"
                    data-aos-duration={400}
                    data-aos-delay={500}
                    data-aos-easing="ease"
                  />
                  <img
                    src={nutrition3}
                    className="img-fluid img-overlay"
                    style={{ width: "80%", display: "block" }}
                    data-aos="fade-left"
                    data-aos-duration={400}
                    data-aos-delay={600}
                    data-aos-easing="ease"
                  />
                  <img
                    src={nutrition4}
                    className="img-fluid img-overlay"
                    style={{ width: "80%", display: "block" }}
                    data-aos="fade-left"
                    data-aos-duration={400}
                    data-aos-delay={700}
                    data-aos-easing="ease"
                  />
                  <img
                    src={nutrition5}
                    className="img-fluid img-overlay"
                    style={{ width: "80%", display: "block" }}
                    data-aos="fade-down"
                    data-aos-duration={400}
                    data-aos-delay={650}
                    data-aos-easing="ease"
                  />
                </div>
              </div>
              <div
                className="col-12 col-sm-12 col-md-6 col-lg-5"
                data-aos="fade-up"
                data-aos-duration={500}
                data-aos-delay={300}
                data-aos-easing="ease"
              >
                <span className="pill-section d-inline-block primary-green mb-3">
                  Nutrition
                </span>
                <h2 className="title-2 mb-4 white">
                  Un nutritioniste
                  <br />
                  dans votre poche
                </h2>
                <p className="text-2 white-70 mb-4">
                  Fleesh, votre coach nutritionnel personnel, propulsé par
                  l'intelligence artificielle qui apprend de vos habitudes et
                  objectifs constamment pour vous créer des plans de repas
                  parfaitement adaptés, vous accompagnant à chaque repas, où que
                  vous soyez.
                </p>
                <div className="advantage mb-2 d-flex align-items-center justify-content-start">
                  <img
                    className="round-icon me-2"
                    src={checkMarkWhite}
                    alt=""
                  />
                  <p className="text-2-bold white m-0">
                    Un accompagnement constant et intelligent
                  </p>
                </div>
                <div className="advantage mb-5 d-flex align-items-center justify-content-start">
                  <img
                    className="round-icon me-2"
                    src={checkMarkWhite}
                    alt=""
                  />
                  <p className="text-2-bold white m-0">
                    Optimisation dynamique de vos repas
                  </p>
                </div>
                <a
                  className="white-btn"
                  href=""
                  data-aos="fade-up"
                  data-aos-duration={800}
                  data-aos-delay={400}
                  data-aos-easing="ease"
                >
                  Apple Store
                </a>
                <a
                  className="white-btn ms-2"
                  href=""
                  data-aos="fade-up"
                  data-aos-duration={800}
                  data-aos-delay={400}
                  data-aos-easing="ease"
                >
                  Google Store
                </a>
              </div>
            </div>
          </div>
        </section>
    )
}