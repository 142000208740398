import AnalyseImg from "../../images/icones/analyse.png";
import timserFlash from "../../images/icones/timer-flash-line.svg"
import filList3Img from "../../images/icones/file-list-3-line.svg"
import LogoWhite from "../../images/logo-white.svg";

export const IaSection = () => {
    return (
        <section
        id="IA"
        className="section-oneee light-text  white-background"
      >
        <div className="container-one">
          <div className="row d-flex justify-content-center">
            <div className="col-12 col-md-12 col-lg-7 text-center">
              <img src={LogoWhite} className="mb-3" alt="" />
              <h2 className="title-base mb-4">
                L'intelligence artificielle au cœur de votre alimentation
              </h2>
              <p className="text-2 mb-5 white-70">
                Plongez dans un monde où l'intelligence artificielle personnalise
                votre alimentation pour optimiser chaque aspect de votre régime,
                garantissant une approche sur mesure qui évolue avec vous.
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-sm-4 col-md-4 col-lg-3">
              <div
                className="card-ia"
                style={{
                  border: "1px solid rgba(275, 275, 275, 0.2)",
                  padding: 30,
                  borderRadius: 15,
                  minHeight: 350
                }}
              >
                <img className="iconn-ai mb-3" src={AnalyseImg} alt="" />
                <h3 className="title-4">
                  Analyse <br />
                  comportementale
                </h3>
                <p className="text-3 white-70">
                  Notre IA scrute vos habitudes alimentaires, objectifs personnels, et
                  préférences gustatives pour offrir une personnalisation sans
                  précédent. Elle conçoit une alimentation qui vous correspond,
                  rendant chaque repas aussi unique que vous.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-4 col-md-4 col-lg-3">
              <div
                className="card-ia"
                style={{
                  border: "1px solid rgba(275, 275, 275, 0.2)",
                  padding: 30,
                  borderRadius: 15
                }}
              >
                <img
                  className="iconn-ai mb-3"
                  src={timserFlash}
                  alt=""
                />
                <h3 className="title-4">
                  Adaptation <br /> dynamique
                </h3>
                <p className="text-3 white-70">
                  À mesure que vos goûts et objectifs évoluent, Fleesh s'adapte. Elle
                  modifie intelligemment les portions, ingrédients et recettes pour
                  s'assurer que votre parcours alimentaire reste aligné avec vos
                  aspirations de bien-être.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-4 col-md-4 col-lg-3">
              <div
                className="card-ia"
                style={{
                  border: "1px solid rgba(275, 275, 275, 0.2)",
                  padding: 30,
                  borderRadius: 15,
                  minHeight: 350
                }}
              >
                <img
                  className="iconn-ai mb-3"
                  src={filList3Img}
                  alt=""
                />
                <h3 className="title-4">
                  Évolution <br /> constante{" "}
                </h3>
                <p className="text-3 white-70">
                  L'intelligence artificielle de Fleesh se renforce à chaque
                  interaction. Elle affine sa compréhension de vos préférences et
                  besoins pour devenir un allié de plus en plus efficace dans votre
                  quête d'une meilleure santé.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    )
}